<template>
	<div>
		<base-data-table
			v-bind="currentTable.attrs"
			:title="currentTable.title"
			:tabs="tabs"
			:current-tab.sync="currentTab"
			translate
			@view="onViewOffer"
			@update="onUpdateOffer"
			@delete="onDeleteOffer"
			@status="onChangeStatus"
			@click:add="onClickAdd"
		>
			<!-- SERIAL -->
			<template v-slot:[`item.serial`]="{ index }">
				{{
					currentTable.attrs.provider.perPage *
						(currentTable.attrs.provider.currentPage - 1) +
					index +
					1
				}}
			</template>

			<!-- NAME -->
			<template v-slot:[`item.displayName`]="{ item, index }">
				<div class="tw-flex tw-items-center tw-space-s-5">
					<v-img
						:ref="`imgRef-${index}`"
						:src="$image($get(item, 'attachments.0.attachedLink'))"
						cover
						width="48"
						height="48"
						class="tw-rounded-md tw-flex-none"
						@error="$onImgError({ ref: $refs[`imgRef-${index}`] })"
					/>
					<div>
						<div>{{ item.name }}</div>
						<div
							class="tw-opacity-70 tw-font-medium tw-text-xs tw-truncate tw-max-w-xs"
						>
							{{ $t('length-products', [item.products.length]) }}
						</div>
					</div>
				</div>
			</template>

			<!-- STATUS -->
			<template v-slot:[`item.status`]="{ item }">
				<BaseStatus
					:status="item.status"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				/>
			</template>

			<!-- PAYMENT -->
			<template v-slot:[`item.payment`]="{ item }">
				<div
					class="tw-uppercase tw-text-xs tw-font-bold"
					v-if="
						item.order &&
						item.order.payments.length &&
						item.order.payments[0].paymentStatus === 'authorised'
					"
				>
					{{ $t('paid') }}
				</div>
				<div class="tw-uppercase tw-text-xs tw-font-bold" v-else>
					<v-btn
						v-if="!$currentUser.isAdmin"
						class="tw-rounded-lg"
						width=""
						height="44"
						outlined
						@click="onClickPayNow(item)"
						color="#F2CA51"
					>
						{{ $t('payNow') }}
					</v-btn>
				</div>
			</template>
		</base-data-table>

		<!-- OFFER FORM -->
		<v-dialog
			v-model="offerForm.dialog"
			persistent
			scrollable
			max-width="1198"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<OfferForm
				v-if="offerForm.dialog"
				:value="offerForm.dialog"
				:offerData="offerForm.data"
				:is-view.sync="offerForm.view"
				:onUpdate="offerForm.edit"
				:error="offerForm.errors"
				:update-function="handleUpdateOffer"
				:create-function="handleCreateOffer"
				@onClose="onResetOfferForm"
			/>
		</v-dialog>

		<!-- MarketingOfferForm -->
		<v-dialog
			persistent
			max-width="1198"
			v-model="marketingOffer.dialog"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<MarketingOfferForm
				:value="marketingOffer.dialog"
				:offerData="marketingOffer.data"
				:is-view.sync="marketingOffer.view"
				:error="marketingOffer.errors"
				@onClose="onResetMarkettingOfferForm"
			/>
		</v-dialog>

		<!-- DELETE CONFIRMATION -->
		<v-dialog
			v-model="confirmDialog"
			persistent
			max-width="676"
			content-class="tw-rounded-3xl tw-bg-white"
		>
			<v-card elevation="0" class="tw-p-0 tw-mx-0">
				<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
					<h1 class="tw-text-2xl tw-font-semibold">
						{{
							currentTab == 'offers'
								? 'Delete Offer'
								: 'Delete Marketing Offer'
						}}
					</h1>
					<div
						class="tw-text-black tw-text-opacity-25 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
					>
						{{ $t('action-cant-be-undone') }}
					</div>
					<v-card-actions class="tw-flex tw-space-s-4">
						<v-btn
							outlined
							depressed
							color="#DF9999"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							@click="confirmDialog = false"
						>
							<span class="tw-capitalize tw-text-lg tw-font-medium">
								{{ $t('actions.cancel') }}
							</span>
						</v-btn>
						<v-btn
							:loading="loading"
							dark
							depressed
							color="#DF9999"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							@click="
								currentTab == 'offers'
									? handleDeleteOffer()
									: handleDeleteMarketingOffer()
							"
						>
							<span class="tw-capitalize tw-text-lg tw-font-medium">
								{{ $t('actions.deleteOffer') }}
							</span>
						</v-btn>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import api from '../../api'

import { toFormData } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { PAYTAB_PAYMENT_TYPE } from '../../consts'

import OfferForm from '../../components/forms/OfferForm.vue'
import MarketingOfferForm from '../../components/forms/MarketingOfferForm.vue'

export default {
	name: 'Offers',
	components: {
		OfferForm,
		MarketingOfferForm,
	},
	data: () => ({
		errors: {},
		currentTab: 'offers',
		confirmDialog: false,
		loading: false,
		view: {
			data: null,
			modal: false,
		},
		viewMarketing: {
			data: null,
			modal: false,
		},
		remove: {
			id: null,
			type: 'offer',
			modal: false,
		},
		offerForm: {
			data: { id: null },
			dialog: false,
			edit: false,
			view: false,
			errors: {},
			loading: false,
		},
		marketingOffer: {
			data: {},
			errors: {},
			edit: false,
			view: false,
			dialog: false,
			loading: false,
		},
	}),
	created() {
		this.currentTab = this.$route.query.tab ? this.$route.query.tab : 'offers'
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('offers', ['$offers', '$marketingOffers']),
		actions() {
			return [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye-outline',
				},
				{
					text: this.$t('actions.editDetails'),
					event: 'update',
					icon: 'mdi-pencil-outline',
				},
				{
					icon: 'mdi-minus-box-outline',
					event: 'status',
					text: (item) => {
						return item.status
							? this.$t('actions.deactivate')
							: this.$t('actions.activate')
					},
				},
				{
					text: this.$t('actions.delete'),
					event: 'delete',
					icon: 'mdi-delete-outline',
				},
			]
		},
		marketingActions() {
			return [
				{
					text: this.$t('actions.delete'),
					event: 'delete',
					icon: 'mdi-delete-outline',
				},
			]
		},
		tabs() {
			return [
				{ text: this.$t('offers'), value: 'offers' },
				{ text: this.$t('marketing-offers'), value: 'marketingOffers' },
			]
		},
		headers() {
			let headers = [
				{ text: 'headers.ns', value: 'serial' },
				{ text: 'headers.displayName', value: 'displayName' },
				{ text: 'headers.mainCategory', value: 'mainCategory.0.name' },
				{
					text: 'headers.price',
					value: 'price',
					computed: (item) => {
						return (
							item.currency.currency +
							' ' +
							item.products.reduce((total, op) => {
								return total + op.pivot.price * op.pivot.quantity
							}, 0)
						)
					},
				},
				{
					text: 'headers.offeredPrice',
					value: 'offeredPrice',
					computed: (item) => {
						return `${item.currency.currency} ${item.finalPrice}`
					},
				},
				{
					text: 'headers.discount',
					value: 'discount',
					computed: (item) => {
						return `${
							item.offeringType === 'amount'
								? item.currency.currency + ' '
								: ''
						}${item.discount}${
							item.offeringType === 'percentage' ? '%' : ''
						}`
					},
				},
				{ text: 'headers.startDate', value: 'startDate' },
				{ text: 'headers.endDate', value: 'endDate' },
				{ text: 'headers.status', value: 'status' },
				{ text: 'headers.actions', value: 'actions' },
			]
			if (this.$currentUser.isAdmin) {
				headers.splice(6, 0, {
					text: 'headers.supplier',
					value: 'supplier.representativeName',
				})
			}
			return headers
		},
		marketingHeaders() {
			let headers = [
				{ text: 'headers.ns', value: 'serial' },
				{
					text: 'headers.mainCategory',
					value: 'productMainCategory.0.name',
				},
				{ text: 'headers.subCategory', value: 'productSubCategory.0.name' },
				{ text: 'headers.status', value: 'status' },
				{ text: 'headers.payment', value: 'payment' },
				{ text: 'headers.actions', value: 'actions' },
			]
			if (this.$currentUser.isAdmin) {
				headers.splice(3, 0, {
					text: 'headers.supplier',
					value: 'supplier.representativeName',
				})
			}
			return headers
		},
		currentTable() {
			return {
				offers: {
					title: this.$t('offers'),
					attrs: {
						actions: this.actions,
						provider: this.$offers,
						headers: this.headers,
						fetchFunction: this.getOffers,
					},
				},
				marketingOffers: {
					title: this.$t('marketing-offers'),
					attrs: {
						add: !this.$currentUser.isAdmin,
						actions: this.marketingActions,
						provider: this.$marketingOffers,
						headers: this.marketingHeaders,
						fetchFunction: this.getMarketingOffers,
					},
				},
			}[this.currentTab]
		},
	},

	methods: {
		// ...mapActions('suppliers', ['getSupplier']),
		...mapActions('settings', ['setSettingsState']),
		// ...mapActions('products', ['getProducts', 'getProductsDependency']),
		// ...mapActions('category', ['getCategories', 'getCategoriesForProduct']),
		...mapActions('offers', [
			'getOffers',
			'deleteOffer',
			'updateOffer',
			'getMarketingOffers',
			'deleteMarketingOffer',
			// 'updateMarketingOffer',
			'createOffer',
			// 'countBuyer',
		]),
		async handleGetOffers(paginate = null) {
			if (!this.$offers.data.length) this.isOffersLoaded = false
			else this.isOffersRefreshing = true

			paginate = paginate || {
				page: this.$offers.currentPage,
				perPage: this.$offers.perPage,
			}
			await this.getOffers({ ...paginate, ...this.searchQuery })
			this.isOffersLoaded = true
			this.isOffersRefreshing = false
		},
		async handleGetMarketingOffers(paginate = null) {
			if (!this.$marketingOffers.data.length)
				this.isMarketingOffersLoaded = false
			else this.isMarketingOffersRefreshing = true

			paginate = paginate || {
				page: this.$marketingOffers.currentPage,
				perPage: this.$marketingOffers.perPage,
			}
			await this.getMarketingOffers({ ...paginate, ...this.searchQuery })
			this.isMarketingOffersLoaded = true
			this.isMarketingOffersRefreshing = false
		},
		async onClickPayNow(offer) {
			let [err, res] = await api.post('payment/create', {
				currency: 'SAR',
				orderId: offer.order.id,
				charge: offer.numberOfBuyers * offer.offerChargePerBuyers,
				type: PAYTAB_PAYMENT_TYPE.MARKETING_OFFER,
			})
			this.setSettingsState({
				currentPayment: {
					tranRef: res.tranRef,
					type: PAYTAB_PAYMENT_TYPE.MARKETING_OFFER,
				},
			})
			!err && location.replace(res.url)
		},
		onChangeStatus(item) {
			const status = item.status
				? this.$t('actions.deactivate')
				: this.$t('actions.activate')
			this.$root.confirmation = {
				title: this.$t('status-offer', [status]),
				message: this.$t('sure-status-offer', [status]),
				handler: () => {
					return this.updateOffer(
						toFormData(
							{
								id: item.id,
								status: !item.status,
							},
							{ _method: 'PATCH' }
						)
					)
				},
			}
		},
		onGetCurrentOffers() {
			this.currentTab === 'offers'
				? this.handleGetOffers()
				: this.handleGetMarketingOffers()
		},
		async handleCreateOffer(data) {
			const body = toFormData(data, { convertCase: 'snake_case' })
			let [err, res] = await this.createOffer(body)
			if (err) {
				return this.$toast.error(
					err.message || this.$t('error-creating-offer')
				)
			}
			this.$toast.success(this.$t('offer-created-successfully'))
			this.onResetOfferForm()
			return [err, res]
		},
		async handleUpdateOffer(data) {
			let [err, res] = await this.updateOffer(
				toFormData(
					data,
					{ _method: 'PATCH' },
					{ convertCase: 'snake_case' }
				)
			)
			if (err) {
				return this.$toast.error(
					err.message || this.$t('error-while-updating-offer')
				)
			}
			this.$toast.success(this.$t('offer-updated-successfully'))
			this.onResetOfferForm()
			return [err, res]
		},
		async handleDeleteOffer() {
			this.loading = true
			let [err] = await this.deleteOffer(this.remove.id)
			if (err) {
				this.loading = false

				return this.$toast.error(this.$t('error-deleting-offer'))
			}
			this.$toast.success(this.$t('offer-deleted-successfully'))
			this.confirmDialog = false
			this.loading = false
		},
		async handleDeleteMarketingOffer() {
			this.loading = true
			let [err] = await this.deleteMarketingOffer(this.remove.id)
			if (err) {
				this.loading = false

				return this.$toast.error(this.$t('error-deleting-offer'))
			}
			this.$toast.success(this.$t('marketing-offer-deleted-successfully'))
			this.confirmDialog = false
			this.loading = false
		},
		onDeleteOffer(data) {
			this.remove.id = data.id
			this.confirmDialog = true
		},
		onResetOfferForm() {
			this.offerForm = {
				data: { id: null },
				dialog: false,
				edit: false,
				view: false,
				errors: {},
				loading: false,
			}
		},
		onResetMarkettingOfferForm() {
			this.marketingOffer = {
				data: { id: null },
				dialog: false,
				edit: false,
				view: false,
				errors: {},
				loading: false,
			}
		},
		onInitOffer() {
			this.onResetOfferForm()
			this.offerForm.dialog = true
		},
		onInitMarketingOffer() {
			this.onResetMarkettingOfferForm()
			this.marketingOffer.dialog = true
		},
		onUpdateOffer(item) {
			this.offerForm.data = item
			this.offerForm.edit = true
			this.offerForm.dialog = true
		},
		onViewOffer(item) {
			this.offerForm.data = item
			this.offerForm.view = true
			this.offerForm.dialog = true
		},
		onClickAdd() {
			if (this.currentTab === 'offers') {
				return this.onInitOffer()
			}
			return this.onInitMarketingOffer()
		},
	},
}
</script>
