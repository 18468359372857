<template>
	<!-- :readonly="onView && !update" -->
	<v-form ref="form" lazy-validation @submit.prevent="validateOffer">
		<v-card class="tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6">
			<div class="tw-flex tw-justify-between tw-items-start">
				<h2
					class="tw-text-22 tw-font-semibold tw-text-black tw-mb-6 lg:tw-mb-12"
				>
					{{ $t('offer-information') }}
				</h2>

				<div v-if="isView && !isUpdate" class="tw-flex tw-space-s-3">
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						tile
						color="#F2CA51"
						outlined
						@click="onUpdateFromView"
						icon
					>
						<v-icon color="tw-text-F2 ">mdi mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="tw-flex tw-space-s-3" v-if="update">
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						color="#F2CA51"
						dark
						@click="validateOffer"
					>
						<v-icon color="tw-text-white">mdi mdi-check</v-icon>
					</v-btn>
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>
			</div>
			<v-card-text>
				<v-row>
					<v-col v-if="$currentUser.isAdmin" cols="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('supplierLabel') }}
						</div>
						<!-- :filter="onFilterSuppliers" -->
						<BaseAutocomplete
							v-model="offer.supplier"
							return-object
							item-value="id"
							:readonly="isView"
							:form-ref="$refs.form"
							:items="$suppliers.data"
							:total="$suppliers.total"
							:on-fetch="onFetchSuppliers"
							item-text="representativeName"
							:per-page="$suppliers.perPage"
							:page="$suppliers.currentPage"
							:loading="$suppliers.isRefetching"
							:rules="[rules.required($t('supplierText'))]"
							:placeholder="$t(`chooseSupplier`)"
						>
							<!-- <template #selection="{item}">
										<div
											class="tw-flex tw-flex-col tw-text-base tw-text-black"
										>
											<span class="tw-font-medium">
												{{ item.representativeName }}({{
													item.company.name
												}})
											</span>
											<span class="tw-opacity-80">
												{{ item.company.email }}
											</span>
										</div>
									</template>
									<template #item="{item}">
										<v-list-item-content>
											<v-list-item-title class="tw-font-medium">
												{{ item.representativeName }}({{
													item.company.name
												}})
											</v-list-item-title>
											<v-list-item-subtitle>
												{{ item.company.email }}
											</v-list-item-subtitle>
										</v-list-item-content>
									</template> -->
						</BaseAutocomplete>
						<!-- <v-autocomplete
							flat
							dense
							outlined
							clearable
							item-value="value"
							item-text="label"
							class=" user-input"
							hide-details="auto"
							:placeholder="$t('selectSupplier')"
							:items="suppliers"
							:readonly="isView"
							:rules="[rules.required($t('selectSupplier'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							v-model="offer.supplierId"
						/> -->
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('offer-name') }}
						</div>
						<v-text-field
							v-model="offer.name"
							:readonly="isView"
							:placeholder="$t('offer-name')"
							:rules="[rules.required('Offer Name')]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="error.name ? error.name[0] : []"
							outlined
							flat
							class="user-input"
							hide-details="auto"
							dense
						/>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('productForm.tags') }}
						</div>
						<v-combobox
							flat
							dense
							outlined
							multiple
							clearable
							item-value="id"
							item-text="name"
							class="user-input"
							hide-details="auto"
							:placeholder="$t('select-tags')"
							:items="$tags"
							:readonly="isView"
							:rules="[rules.required($t('productForm.tags'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							v-model="offer.tags"
						/>
					</v-col>

					<v-col cols="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('productDescription') }}
						</div>

						<v-textarea
							flat
							dense
							outlined
							auto-grow
							rows="2"
							hide-details="auto"
							class="custom-outlined tw-rounded-md"
							:placeholder="$t('write-product-description')"
							:readonly="isView"
							:rules="[rules.required($t('description'))]"
							:error-messages="error.phone ? error.phone[0] : []"
							v-model="offer.description"
						></v-textarea>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="tw-flex">
							<div class="tw-flex-1">
								<div
									class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
								>
									{{ $t('select') }} {{ $t('headers.mainCategory') }}
								</div>
								<v-select
									flat
									dense
									outlined
									item-text="label"
									item-value="value"
									class="user-input"
									hide-details="auto"
									:placeholder="$t('categories')"
									:readonly="isView"
									:items="categories"
									:rules="[rules.required($t('categories'))]"
									:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
									:error-messages="
										error.mainCategoryId
											? error.mainCategoryId[0]
											: []
									"
									v-model="offer.mainCategoryId"
								/>
							</div>
							<!-- v-if="!onView" -->
							<v-btn
								depressed
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								color="secondary"
								class="tw-mt-10"
								@click="initCategoryModal"
							>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="tw-flex">
							<div class="tw-flex-1">
								<div
									class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
								>
									{{ $t('select') }} {{ $t('headers.subCategory') }}
								</div>
								<!-- :rules="[rules.required('Categories')]" -->
								<v-select
									v-model="offer.subCategoryId"
									:readonly="isView"
									:items="subCategories"
									:placeholder="$t('categories')"
									:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
									flat
									dense
									outlined
									item-text="label"
									item-value="value"
									class="user-input"
									hide-details="auto"
								/>
							</div>
							<!-- v-if="!onView" -->
							<v-btn
								depressed
								:disabled="!offer.mainCategoryId"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								color="secondary"
								class="tw-mt-10"
								@click="onAddSubCategory"
							>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
					</v-col>
					<v-col cols="12" sm="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('products') }}
						</div>
						<v-menu
							:disabled="isView"
							:close-on-content-click="false"
							offset-y
							max-width="512"
							transition="slide-y-transition"
						>
							<template #activator="{ on, attrs }">
								<div class="tw-relative tw-rounded-lg tw-border">
									<div
										v-on="on"
										v-bind="attrs"
										class="tw-absolute tw-inset-0 tw-z-10"
									/>
									<div
										v-if="!offer.products.length"
										class="tw-h-12 tw-px-4 tw-flex tw-items-center tw-text-base tw-opacity-50"
									>
										{{ $t('select-one-or-more-products') }}
									</div>
									<div
										v-else
										class="tw-flex tw-flex-wrap tw--ml-3 tw--mt-3 tw-p-3"
									>
										<OfferProductListItem
											v-for="(product, index) in offer.products"
											:key="product.id"
											:product="product"
											:show-actions="!isView"
											is-selected
											class="tw-ml-3 tw-mt-3 tw-max-w-xs tw-rounded-md tw-z-20 tw-relative"
											@close="offer.products.splice(index, 1)"
										/>
									</div>
								</div>
							</template>
							<v-card>
								<v-card-title>
									{{ $t('select-products') }}
								</v-card-title>
								<v-card-subtitle>
									{{
										$t('select-the-offer-products-and-there-quantity')
									}}
								</v-card-subtitle>

								<div class="tw-px-4">
									<v-text-field
										v-model="productsSearchText"
										:loading="$products.isRefreshing"
										:placeholder="$t('search-products')"
										dense
										outlined
										clearable
										hide-details
										@input="onSearchProducts"
									/>
								</div>
								<v-divider class="tw-mt-4" />
								<v-card-text
									class="tw-overflow-y-auto"
									style="max-height: 70vh"
								>
									<v-list nav>
										<OfferProductListItem
											v-for="product in $products.data"
											:key="product.id"
											:product="product"
											:is-selected="isProductSelected(product)"
											@click="onSelectProduct"
										/>
									</v-list>
								</v-card-text>
								<v-card-actions>
									<v-spacer />
									<v-pagination
										:value="$products.currentPage"
										:length="
											Math.ceil($products.total / $products.perPage)
										"
										:disabled="$products.isRefreshing"
										outlined
										color="primary"
										@input="getProducts({ page: $event })"
									/>
								</v-card-actions>
							</v-card>
						</v-menu>
					</v-col>
					<v-col cols="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('offer-attachment') }}
						</div>
						<BaseDropZone
							multiple
							:readonly="isView"
							:error="errorAttachments"
							v-model="offer.attachments"
							@input="errorAttachments = false"
						/>
						<div
							v-if="errorAttachments"
							class="tw-text-sm tw-text-red-500 tw-mt-1"
						>
							{{ $t('attachments') }} {{ $t('isRequired') }}
						</div>
					</v-col>

					<v-col cols="12" sm="6">
						<BaseDatePicker
							v-model="offer.startDate"
							:readonly="isView"
							:label="$t('startDate')"
							:rules="[rules.required($t('startDate'))]"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:min="$m().format('YYYY-MM-DD')"
							:max="offer.endDate"
							input-class="user-input fieldset-f0"
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<BaseDatePicker
							v-model="offer.endDate"
							:readonly="isView"
							:label="$t('endDate')"
							:rules="[rules.required($t('endDate'))]"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:min="offer.startDate"
							input-class="user-input fieldset-f0"
						/>
					</v-col>

					<v-col cols="12" sm="4">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('offer-type') }}
						</div>
						<v-select
							flat
							dense
							outlined
							hide-details="auto"
							class="user-input"
							:placeholder="$t('select-offer')"
							:readonly="isView"
							:items="['percentage', 'amount']"
							:rules="[rules.required($t('offer-type'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							v-model="offer.offeringType"
						>
						</v-select>
					</v-col>
					<!-- for amount -->
					<v-col v-if="offer.offeringType == 'amount'" cols="12" sm="4">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('discount-amount') }}
						</div>
						<v-text-field
							v-model="offer.discount"
							:readonly="isView"
							:placeholder="$t('amount')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:rules="[
								rules.required($t('categories')),
								rules.max(productsPrice),
							]"
							flat
							dense
							outlined
							type="number"
							class="user-input"
							hide-details="auto"
						/>
						<div
							v-if="offer.discount"
							class="tw-pt-2 tw-text-primary"
						></div>
					</v-col>
					<v-col
						v-if="offer.offeringType == 'percentage'"
						cols="12"
						sm="4"
					>
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('percentage-amount') }}
						</div>
						<v-text-field
							v-model="offer.discount"
							:readonly="isView"
							:placeholder="$t('percentage-amount')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:rules="[rules.required($t('amount')), rules.max(100)]"
							flat
							dense
							outlined
							prefix="%"
							type="number"
							class="user-input"
							hide-details="auto"
						/>
						<!-- <div v-if="offer.discount" class="tw-text-primary tw-pt-2">
							After Discount Final Price {{ productsFinalPrice }}
						</div> -->
					</v-col>
					<v-col>
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('currency') }}
						</div>
						<v-select
							outlined
							:items="$currencies"
							flat
							:rules="[rules.required($t('currency'))]"
							class="user-input"
							hide-details="auto"
							v-model="offer.currencyId"
							item-value="id"
							item-text="currency"
							:placeholder="$t('select-currency')"
							dense
							:readonly="isView"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
						/>
					</v-col>
				</v-row>
				<div class="tw-flex tw-space-s-10 tw-mt-6">
					<div class="tw-text-xs">
						{{ $t('productForm.productPrice') }}
						<span class="tw-font-bold">{{ productsPrice }}</span>
					</div>
					<div class="tw-text-xs">
						{{ $t('price-after-sale') }}
						<span class="tw-font-bold">{{
							+productsFinalPrice.toFixed(2)
						}}</span>
					</div>
				</div>
			</v-card-text>
			<v-card-actions v-if="!isView" class="tw-mt-7 tw-flex tw-p-0">
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetForm"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}</span
					>
				</v-btn>
				<v-btn
					depressed
					height="58"
					type="submit"
					color="primary"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					:loading="isLoading"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>{{ $t('actions.submit') }}</span
					>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'

import UseFilePicker from '../../mixins/UseFilePicker'
import OfferProductListItem from '../OfferProductListItem.vue'
import { omitEmpties } from 'vuelpers'

export default {
	name: 'offerForm',
	components: { OfferProductListItem },
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'max'],
		}),
	],
	props: {
		error: Object,
		offerData: Object,
		permissions: Array,
		value: {
			type: Boolean,
			default: false,
		},
		isView: {
			type: Boolean,
			default: false,
		},
		onUpdate: {
			type: Boolean,
			default: false,
		},
		updateFunction: {
			type: Function,
			required: true,
		},
		createFunction: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			offer: {},
			update: false,
			isLoading: false,
			startDateMenu: false,
			endDateMenu: false,
			errorAttachments: false,
			//
			productsSearchText: '',
			isPendingProductsSearch: false,
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(value) {
				if (!value) return this.onResetForm()

				this.offer = this.initialOffer(this.offerData)
				this.getSuppliers()
				this.getProducts()
				this.getProductsDependency({ type: 'offer' })
			},
		},
		'offerData.id': {
			immediate: true,
			handler(productId) {
				if (!productId) return //return this.onResetForm()
				console.log(this.offerData)
				this.offer = this.initialOffer(this.offerData)
			},
		},
		'offer.mainCategoryId'() {
			if (!this.isLoaded) return
			if (
				!this.subCategories.some((sCat) => {
					return sCat.value === this.offer.subCategoryId
				})
			) {
				this.offer.subCategoryId = ''
			}
		},
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('offers', ['$suppliers']),
		...mapGetters('category', ['$offerCategories']),
		...mapGetters('products', ['$products', '$tags', '$currencies']),
		isUpdate() {
			return !!this.offer.id && !this.isView
		},
		submitFunction() {
			return this.isUpdate ? this.updateFunction : this.createFunction
		},
		finalPriceError() {
			return this.productsFinalPrice < 0
		},
		productsFinalPrice() {
			if (!this.offer?.products?.length) return 0
			if (!this.offer.discount) return this.productsPrice
			if (this.offer.offeringType === 'amount') {
				return this.productsPrice - this.offer.discount
			}
			return (this.productsPrice / 100) * (100 - this.offer.discount)
		},
		productsPrice() {
			return this.offer.products.reduce((total, op) => {
				return total + (op?.pivot?.price || op.price)
			}, 0)
		},
		currencies() {
			return this.$currencies.map((currency) => ({
				value: currency.id,
				label: currency.currency,
			}))
		},
		categories() {
			return this.$offerCategories.data
				.filter((cat) => cat.isMain)
				.map((cat) => {
					return {
						value: cat.id,
						label: cat.name,
					}
				})
		},
		subCategories() {
			if (!this.offer.mainCategoryId) return []
			let subCategories = this.$offerCategories.data.filter((cat) => {
				return (
					!cat.isMain && cat.mainCategoryId === this.offer.mainCategoryId
				)
			})
			return subCategories.map((subCat) => {
				return {
					value: subCat.id,
					label: subCat.name,
				}
			})
		},
	},
	methods: {
		...mapActions('offers', ['getSuppliers']),
		...mapActions('category', ['setCategoryState']),
		...mapActions('products', ['getProducts', 'getProductsDependency']),
		onFetchSuppliers(query = {}) {
			this.getSuppliers({
				page: this.$suppliers.currentPage,
				perPage: this.$suppliers.perPage,
				...query,
			})
		},
		async onSearchProducts() {
			if (this.$products.isRefreshing) {
				this.isPendingProductsSearch = true
				return
			}

			await this.getProducts(
				omitEmpties({
					filter: { search: this.productsSearchText },
				})
			)

			if (this.isPendingProductsSearch) {
				this.isPendingProductsSearch = false
				this.onSearchProducts()
			}
		},
		isProductSelected(product) {
			return this.offer.products.some((item) => {
				return item.id === product.id
			})
		},
		onSelectProduct(product) {
			if (this.isProductSelected(product)) {
				this.offer.products = this.offer.products.filter(
					(item) => item.id !== product.id
				)
				return
			}
			this.offer.products.push(product)
		},
		onAddSubCategory() {
			this.initCategoryModal({
				isMain: 0,
				mainCategoryId: this.offer.mainCategoryId,
			})
		},
		initCategoryModal(config = {}) {
			this.setCategoryState({
				create: {
					modal: true,
					isLoading: false,
					data: {
						name: '',
						isMain: 1,
						type: 'offer',
						...config,
					},
				},
			})
		},
		initialOffer(v = {}) {
			return {
				id: v?.id,
				name: v?.name || '',
				price: v?.price || 0,
				discount: v?.discount || 0,
				description: v?.description || '',
				subCategoryId: get(v, 'subCategory.0.id', ''),
				mainCategoryId: get(v, 'mainCategory.0.id', ''),
				endDate: v?.endDate || '',
				startDate: v?.startDate || '',
				currencyId: v?.currencyId,
				supplier: v?.supplier,
				supplierId: v?.supplierId,
				tags: v?.tags ?? [],
				offeringType: v?.offeringType || 'amount',
				products: v?.products || [],
				// products: v?.products ? v.products.map((p) => p.id) : [],
				attachments:
					v?.attachments?.map((attachment) => {
						return {
							id: attachment.id,
							src: this.$image(attachment.attachedLink),
						}
					}) ?? [],
			}
		},
		onResetForm() {
			// this.$refs?.form?.reset()
			this.update = false
			this.offer = this.initialOffer()
			this.$emit('onClose')
		},
		async validateOffer() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.errorAttachments = this.offer.attachments.length === 0
			if (this.errorAttachments) return
			let offer = {
				...this._.omit(this.offer, ['supplier']),
				finalPrice: this.productsFinalPrice,
				supplierId:
					this.offer.supplier?.id ||
					this.$get(this.$currentUser, 'supplier.0.id'),

				endDate: this.$m(this.offer.endDate).format(
					this.$t('yyyy-mm-dd-hh-mm-ss')
				),
				startDate: this.$m(this.offer.startDate).format(
					this.$t('yyyy-mm-dd-hh-mm-ss')
				),
				products: this.offer.products.map((product, index) => {
					const op = get(this.offerData, `products.${index}`)
					return {
						id: op?.productId ?? product.id,
						quantity: op?.pivot?.quantity ?? 1,
						price: op?.pivot?.price || product.price,
					}
				}),
				// ATTACHMENTS
				previousAttachments: this.offer.attachments
					.filter((image) => image.id)
					.map((image) => image.id),
				attachments: this.offer.attachments
					.filter((image) => !!image.file)
					.map((image) => image.file),
				// TAGS
				...this.offer.tags.reduce(
					(acc, tag) => {
						if (typeof tag === 'string') acc.newTags.push(tag)
						else acc.tags.push(tag.id)
						return acc
					},
					{ tags: [], newTags: [] }
				),
			}
			//
			this.isLoading = true
			await this.submitFunction(offer)
			this.isLoading = false
		},
		onUpdateFromView() {
			this.$emit('update:is-view', false)
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
