<template>
	<!-- :readonly="onView && !update" -->
	<v-form ref="form" lazy-validation @submit.prevent="validateOffer">
		<v-card
			class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
		>
			<div class="tw-flex tw-justify-between tw-items-start">
				<h2
					class="tw-text-22 tw-font-semibold tw-text-black tw-mb-6 lg:tw-mb-12"
				>
					{{ $t('marketing-offer') }}
				</h2>

				<div v-if="isView && !isUpdate" class="tw-flex tw-space-s-3">
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						tile
						color="#F2CA51"
						outlined
						@click="onUpdateFromView"
						icon
					>
						<v-icon color="tw-text-F2 ">mdi mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetUserForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="tw-flex tw-space-s-3" v-if="update">
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						color="#F2CA51"
						dark
						@click="validateOffer"
					>
						<v-icon color="tw-text-white">mdi mdi-check</v-icon>
					</v-btn>
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetUserForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>
			</div>
			<div>
				<v-row>
					<v-col cols="12" sm="6" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('buyerCategory') }}
						</div>
						<v-select
							v-model="mOffer.buyerMainCategoryId"
							:readonly="isView"
							:items="categories"
							:rules="[rules.required($t('categories'))]"
							:error-messages="error.buyerMainCategoryId"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:placeholder="$t('select-category-0')"
							flat
							dense
							outlined
							item-text="label"
							item-value="value"
							class="user-input"
							hide-details="auto"
						/>
					</v-col>

					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('buyerSubCategory') }}
						</div>

						<v-select
							v-model="mOffer.buyerSubCategoryId"
							:readonly="isView"
							:items="subCategories"
							:rules="[rules.required($t('subCategory'))]"
							:error-messages="error.buyerSubCategoryId"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:placeholder="$t('select-category-0')"
							flat
							dense
							outlined
							item-text="label"
							item-value="value"
							class="user-input"
							hide-details="auto"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('product') }} {{ $t('headers.mainCategory') }}
						</div>
						<v-select
							v-model="mOffer.productMainCategoryId"
							flat
							dense
							outlined
							item-text="label"
							item-value="value"
							class="user-input"
							hide-details="auto"
							:placeholder="$t('select-category-0')"
							:readonly="isView"
							:items="productCategories"
							:rules="[rules.required($t('headers.mainCategory'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="error.productMainCategoryId"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('productForm.productSubCategory') }}
						</div>
						<v-select
							v-model="mOffer.productSubCategoryId"
							:readonly="isView"
							:items="productSubCategories"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:placeholder="$t('select-category-0')"
							flat
							dense
							outlined
							item-text="label"
							class="user-input"
							item-value="value"
							hide-details="auto"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('totalBuyers') }}
						</div>
						<v-text-field
							:value="$buyerCount"
							:readonly="isView"
							:placeholder="$t('number-of-buyers')"
							:rules="[rules.required($t('categories'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							dense
							outlined
							disabled
							item-value="value"
							hide-details="auto"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('number-of-buyers') }}
						</div>
						<v-text-field
							v-model="mOffer.numberOfBuyers"
							:placeholder="$t('number-of-buyers')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							dense
							outlined
							item-text="label"
							item-value="value"
							hide-details="auto"
						/>
					</v-col>

					<v-col cols="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						></div>
						<v-select
							v-model="mOffer.products"
							:items="products"
							:readonly="isView"
							:value="$buyerCount"
							:rules="[rules.required($t('products'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:placeholder="$t('select-trial-products')"
							flat
							dense
							multiple
							outlined
							type="number"
							item-text="label"
							class="user-input"
							hide-details="auto"
							item-value="value.trialProduct.id"
						/>
					</v-col>
				</v-row>
			</div>
			<v-card-actions class="tw-mt-7 tw-flex tw-p-0">
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetUserForm"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}</span
					>
				</v-btn>
				<v-btn
					depressed
					height="58"
					type="submit"
					color="primary"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					:loading="isLoading"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>{{ $t('actions.submit') }}</span
					>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { get, pick } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'

import UseFilePicker from '../../mixins/UseFilePicker'
import { omitEmpties, toFormData } from 'vuelpers'
import { PAYTAB_PAYMENT_TYPE } from '../../consts'
import api from '../../api'
const initialOffer = (value = {}) => ({
	products: [],
	discount: 0,
	numberOfBuyers: 1,
	offeringType: 'amount',
	buyerMainCategoryId: '',
	buyerSubCategoryId: '',
	productMainCategoryId: '',
	productSubCategoryId: '',
	...value,
})
export default {
	name: 'marketingOfferForm',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required'],
		}),
	],
	props: {
		error: Object,
		value: {
			type: Boolean,
			default: false,
		},
		isView: {
			type: Boolean,
			default: false,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mOffer: initialOffer(),

			update: false,
			isLoading: false,

			startDateMenu: false,
			endDateMenu: false,

			errorAttachments: false,
		}
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.countBuyer()
				this.getProductsDependency({ type: 'buyer' })
				this.getCategoriesForProduct({ type: 'product' })
			},
		},
		'offer.id': {
			immediate: true,
			handler(productId) {
				if (!productId) return
				if (this.isUpdate) {
					this.mOffer = initialOffer({
						...pick(this.offer, ['id', 'products']),
						buyerMainCategoryId: this.offer.mainCategory.length
							? this.offer.mainCategory[0].id
							: '',
						buyerSubCategoryId: this.offer.subCategory.length
							? this.offer.subCategory[0].id
							: '',
					})
				}
			},
		},
		'mOffer.buyerSubCategoryId'() {
			if (!this.isLoaded) return
			this.onChangeBuyerCategory()
		},
		'mOffer.buyerMainCategoryId'() {
			if (!this.isLoaded) return
			if (
				!this.subCategories.some((sCat) => {
					return sCat.value === this.mOffer.buyerSubCategoryId
				})
			) {
				this.mOffer.buyerSubCategoryId = ''
			}
			this.onChangeBuyerCategory()
		},
		productQuery: {
			deep: true,
			handler() {
				this.onGetTrialProducts()
			},
		},
	},
	computed: {
		...mapGetters('offers', ['$buyerCount']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('products', ['$trialProducts']),
		...mapGetters('category', [
			'$buyerCategories',
			'$productCategoriesForMarketing',
		]),
		finalPriceError() {
			return this.productsFinalPrice < 0
		},
		productsFinalPrice() {
			if (!this.mOffer.products.length) return 0
			if (!this.mOffer.discount) return this.productsPrice
			if (this.mOffer.offeringType === 'amount') {
				return this.productsPrice - this.mOffer.discount
			}
			return (this.productsPrice / 100) * (100 - this.mOffer.discount)
		},
		productsPrice() {
			return this.mOffer.products.reduce((total, offer) => {
				return total + offer.price
			}, 0)
		},
		products() {
			return this.$trialProducts.data
				.filter((product) => {
					return product.trialProduct
				})
				.map((offer) => {
					return {
						value: offer,
						label: `${offer.name} (${offer.price} ${offer.currency.currency})`,
						thumbnail: offer.attachments.length
							? offer.attachments[0].attachedLink
							: '',
					}
				})
		},
		categories() {
			return this.$buyerCategories.data
				.filter((cat) => cat.isMain)
				.map((cat) => {
					return {
						value: cat.id,
						label: cat.name,
					}
				})
		},
		subCategories() {
			if (!this.mOffer.buyerMainCategoryId) return []
			let subCategories = this.$buyerCategories.data.filter((cat) => {
				return (
					!cat.isMain &&
					cat.mainCategoryId === this.mOffer.buyerMainCategoryId
				)
			})
			return subCategories.map((subCat) => {
				return {
					value: subCat.id,
					label: subCat.name,
				}
			})
		},
		productCategories() {
			return this.$productCategoriesForMarketing.data
				.filter((cat) => cat.isMain)
				.map((cat) => {
					return {
						value: cat.id,
						label: cat.name,
					}
				})
		},
		productSubCategories() {
			if (!this.mOffer.productMainCategoryId) return []
			let category = this.$productCategoriesForMarketing.data.find((cat) => {
				return cat.id == this.mOffer.productMainCategoryId
			})
			if (!category) return []
			return category.childCategories.map((subCat) => {
				return {
					value: subCat.id,
					label: subCat.name,
				}
			})
		},
		// productQuery() {
		// 	return omitEmpties({
		// 		query: this.productSearchQuery,
		// 		subCategory: this.mOffer.productSubCategoryId,
		// 		mainCategory: this.mOffer.productMainCategoryId,
		// 	})
		// },
		productQuery() {
			return omitEmpties({
				subCategory: this.mOffer.productSubCategoryId,
				mainCategory: this.mOffer.productMainCategoryId,
			})
		},
	},
	methods: {
		...mapActions('offers', ['countBuyer']),
		...mapActions('products', ['getTiralProducts', 'getProductsDependency']),
		...mapActions('category', ['getCategoriesForProduct']),
		...mapActions('settings', ['setSettingsState']),
		...mapActions('offers', [
			'countBuyer',
			'createMarketingOffer',
			'updateMarketingOffer',
			'',
		]),

		initialOffer(v = {}) {
			return {
				id: v?.id,
				name: v?.name || '',
				price: v?.price || 0,
				discount: v?.discount || 0,
				description: v?.description || '',
				subCategoryId: get(v, 'subCategory.0.id', ''),
				mainCategoryId: get(v, 'mainCategory.0.id', ''),
				endDate: v?.endDate || '',
				startDate: v?.startDate || '',
				currencyId: v?.currencyId,
				supplierId: v?.supplierId,
				tags: v?.tags ?? [],
				offeringType: v?.offeringType || 'amount',
				products: v?.products ? v.products.map((p) => p.id) : [],
				attachments:
					v?.attachments?.map((attachment) => {
						return {
							id: attachment.id,
							src: this.$image(attachment.attachedLink),
						}
					}) ?? [],
			}
		},
		onResetUserForm() {
			this.$refs?.form?.reset()
			this.update = false
			this.offer = this.initialOffer()
			this.$emit('onClose')
		},
		async validateOffer() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			let offer = {
				number_of_buyers: this.mOffer.numberOfBuyers,
				buyerSubCategoryId: this.mOffer.buyerSubCategoryId,
				buyerMainCategoryId: this.mOffer.buyerMainCategoryId,
				productSubCategoryId: this.mOffer.productSubCategoryId,
				productMainCategoryId: this.mOffer.productMainCategoryId,
				supplierId: this.$currentUser.supplier[0].id,
				trialProducts: this.mOffer.products.map((id) => {
					return {
						quantity: 1,
						id: id,
					}
				}),
			}
			console.log(offer)

			this.isLoading = true
			let request = this.isUpdate
				? this.updateMarketingOffer
				: this.createMarketingOffer
			console.log(request)
			let data = this.isUpdate
				? toFormData(
						offer,
						{ _method: 'PATCH' },
						{ convertCase: 'snake_case' }
				  )
				: toFormData(offer, {}, { convertCase: 'snake_case' })

			let [err, res] = await request(data)
			this.isLoading = false

			if (err) {
				this.$utils.scrollIntoError(this)
				console.log('err', err)
				return
			}

			let [paymentError, paymentRes] = await api.post('payment/create', {
				currency: 'SAR',
				orderId: res.marketingOffer.order.id,
				type: PAYTAB_PAYMENT_TYPE.MARKETING_OFFER,
				charge:
					res.marketingOffer.numberOfBuyers *
					res.marketingOffer.offerChargePerBuyers,
			})
			this.setSettingsState({
				currentPayment: {
					tranRef: paymentRes.tranRef,
					type: PAYTAB_PAYMENT_TYPE.MARKETING_OFFER,
				},
			})

			if (paymentError) {
				this.$utils.scrollIntoError(this)
				return this.$router.push(
					this.$currentUser.isSupplier
						? `/supplier/advertisement`
						: `/${this.role}/ad-spaces`
				)
			}
			location.replace(paymentRes.url)
		},
		onGetTrialProducts() {
			this.getTiralProducts(this.productQuery)
		},
		onUpdateFromView() {
			this.$emit('update:is-view', false)
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
