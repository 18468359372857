<template>
	<v-list-item :input-value="isSelected" @click="$emit('click', product)">
		<v-list-item-avatar>
			<v-img
				ref="imgRef"
				:src="
					$image(
						$get(
							product,
							'attachments.0.attachedLink',
							$placeholder('40', false)
						)
					)
				"
				@error="
					$onImgError({
						ref: $refs.imgRef,
					})
				"
			/>
		</v-list-item-avatar>
		<v-list-item-content>
			<v-list-item-title>
				{{ product.name }}
			</v-list-item-title>
			<v-list-item-subtitle>
				{{ product.description }}
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action>
			<span class="tw-font-semibold tw-text-base">
				{{ $t('sar') }} {{ $commafy(price) }}
			</span>
		</v-list-item-action>
		<v-btn
			v-if="showActions"
			icon
			small
			color="error"
			class="tw-absolute tw--top-2 tw--right-2"
			@click="$emit('close', product)"
		>
			<v-icon>mdi-close</v-icon>
		</v-btn>
	</v-list-item>
</template>
<script>
export default {
	name: 'OfferProductListItem',
	props: {
		isSelected: {
			type: Boolean,
			default: false,
		},
		product: {
			type: Object,
			required: true,
			default: () => ({}),
		},
		showActions: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		price() {
			return this.product.pivot?.price ?? this.product.price
		},
	},
}
</script>
