var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{attrs:{"input-value":_vm.isSelected},on:{"click":function($event){return _vm.$emit('click', _vm.product)}}},[_c('v-list-item-avatar',[_c('v-img',{ref:"imgRef",attrs:{"src":_vm.$image(
					_vm.$get(
						_vm.product,
						'attachments.0.attachedLink',
						_vm.$placeholder('40', false)
					)
				)},on:{"error":function($event){return _vm.$onImgError({
					ref: _vm.$refs.imgRef,
				})}}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.product.description)+" ")])],1),_c('v-list-item-action',[_c('span',{staticClass:"tw-font-semibold tw-text-base"},[_vm._v(" "+_vm._s(_vm.$t('sar'))+" "+_vm._s(_vm.$commafy(_vm.price))+" ")])]),(_vm.showActions)?_c('v-btn',{staticClass:"tw-absolute tw--top-2 tw--right-2",attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.$emit('close', _vm.product)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }